import { useEffect } from 'react'
import NProgress from 'nprogress'
import { Router } from 'next/router'
import type { NextPage } from 'next'
import { Provider } from 'react-redux'
import { Inter } from 'next/font/google'
import type { AppProps } from 'next/app'
import type { ReactElement, ReactNode } from 'react'
import { CacheProvider, EmotionCache } from '@emotion/react'
import TagManager, { TagManagerArgs } from 'react-gtm-module'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import { cn } from '@/lib/utils'
import Layout from '@/layouts/Layout'
import store from '@/store/configureStore'
import { clearCookies } from '@/v1/lib/cookie'
import themeConfig from '@/configs/themeConfig'
import { createEmotionCache } from '@/@core/utils/create-emotion-cache'
import { SettingsConsumer, SettingsProvider } from '@/@core/context/settingsContext'

import '@/styles/sass/app.scss'
import 'react-datepicker/dist/react-datepicker.css'

type ConfigProps = {
  setConfig?: () => void
}

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode
}

interface ExtendedAppProps extends AppProps {
  Component: NextPageWithLayout & ConfigProps
  emotionCache: EmotionCache
}

const queryClient = new QueryClient()

const clientSideEmotionCache = createEmotionCache()

if (themeConfig.routingLoader) {
  Router.events.on('routeChangeStart', () => {
    NProgress.start()
  })
  Router.events.on('routeChangeError', () => {
    NProgress.done()
  })
  Router.events.on('routeChangeComplete', () => {
    NProgress.done()
  })
}

const packageVersion = process.env.NEXT_PUBLIC_APP_VERSION

const interFont = Inter({ subsets: ['latin'] })

// Google Tag Manager
const gtmId = process.env.NEXT_PUBLIC_GOOGLE_TAG_ID || ''

const tagManagerArgs: TagManagerArgs = { gtmId }

const ShipAndStorage = (props: ExtendedAppProps) => {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props

  const setConfig = Component.setConfig

  const getLayout = Component.getLayout || (page => <Layout>{page}</Layout>)

  const isLoaded = typeof window !== 'undefined'

  const storedVersion = isLoaded ? localStorage.getItem('appVersion') : false

  useEffect(() => {
    if (isLoaded) {
      if (!!storedVersion && !!packageVersion && storedVersion !== packageVersion) {
        clearCookies()
        localStorage.clear()
        sessionStorage.clear()
        window.location.reload()
      }

      localStorage.setItem('appVersion', packageVersion ?? '')
    }
  }, [isLoaded, storedVersion])

  useEffect(() => {
    TagManager.initialize(tagManagerArgs)
  }, [])

  return (
    <>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <CacheProvider value={emotionCache}>
            <SettingsProvider {...(setConfig ? { pageSettings: setConfig() } : {})}>
              <SettingsConsumer>
                {({ settings }) => {
                  return (
                    <main className={cn('', interFont.className)}>
                      {getLayout(<Component settings={settings} {...pageProps} />)}
                    </main>
                  )
                }}
              </SettingsConsumer>
            </SettingsProvider>
          </CacheProvider>
        </QueryClientProvider>
      </Provider>
    </>
  )
}

export default ShipAndStorage
